/* ComingSoon.css */
.transition-opacity {
  transition: opacity 1s ease;
}

.toast-slide-up {
  animation: slide-up 0.3s ease-in-out;
}

.animate-leave {
  animation: slide-down 0.3s ease-in-out forwards;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
}
